import { BackSide, Color, Mesh, MeshBasicMaterial, Object3D, SphereGeometry, Texture, Vector3 } from 'three';

import { updateShader } from './Shaders/UpdateShader';

/**
 * Interface representing shader uniforms for the background mesh.
 */
interface BackgroundUniforms {
  backgroundColor: { value: Vector3 };
  isEnvironment: { value: boolean };
  focusLength: { value: number };
}

/**
 * A custom three.js class that defines a background mesh for a 3D scene.
 * The class includes methods to manipulate the mesh, its material, and its shader uniforms.
 */
export class BackgroundMesh extends Object3D {
  private mesh: Mesh; // A three.js Mesh object representing the spherical background geometry.
  private material: MeshBasicMaterial; // Material for rendering the background; does not interact with lights.
  private texture: Texture; // Texture applied to the background material, initialized as empty.
  private backgroundColor: Color; // The background color, represented by a three.js Color object.
  private uniforms: BackgroundUniforms; // Shader uniforms for customizing the material.

  /**
   * Constructs the BackgroundMesh object.
   * @param {number} size - The radius of the spherical background geometry.
   * @param {number} widthSegments - Number of horizontal segments in the sphere geometry.
   * @param {number} heightSegments - Number of vertical segments in the sphere geometry.
   * @param {Color} backgroundColor - Initial color of the background.
   */
  constructor(size: number, widthSegments: number, heightSegments: number, backgroundColor: Color) {
    super(); // Initialize the Object3D base class.

    // Create a sphere geometry with the specified size and segments.
    const geometry = new SphereGeometry(size, widthSegments, heightSegments);

    // Initialize the material with back-side rendering and transparency enabled.
    this.material = new MeshBasicMaterial({ side: BackSide, transparent: true, depthWrite: false });

    // Create a mesh using the sphere geometry and the initialized material.
    this.mesh = new Mesh(geometry, this.material);

    // Ensure the background is rendered before other objects.
    this.mesh.renderOrder = -99999;

    // Invert the scale on the x-axis to correctly render the background from the inside.
    this.mesh.scale.x = -1;

    // Rotate the mesh 180 degrees around the y-axis to properly align it.
    this.mesh.rotateY(Math.PI);

    // Initialize an empty texture.
    this.texture = new Texture();

    // Set the background color and initialize shader uniforms.
    this.backgroundColor = new Color(backgroundColor);
    this.uniforms = {
      backgroundColor: { value: new Vector3(this.backgroundColor.r, this.backgroundColor.g, this.backgroundColor.b) },
      isEnvironment: { value: false }, // Default is not an environment map.
      focusLength: { value: 0.5 }, // Default focus length for depth-related effects.
    };

    // Customize the shader using a hook before compilation.
    this.material.onBeforeCompile = (shader: any) => updateShader(shader, this.uniforms);

    // Add mesh to the Object3D.
    this.add(this.mesh);
  }

  /**
   * Returns the Mesh object for the background geometry.
   * @returns {Mesh} The background mesh.
   */
  public getMesh(): Mesh {
    return this.mesh;
  }

  /**
   * Updates the rotation of the background mesh.
   * @param {number} x - Rotation angle in radians around the X-axis.
   * @param {number} y - Rotation angle in radians around the Y-axis.
   * @param {number} z - Rotation angle in radians around the Z-axis.
   */
  public setRotation(x: number, y: number, z: number): void {
    this.mesh.rotation.set(x, y, z);
  }

  /**
   * Updates the position of the background mesh.
   * @param {number} x - X-axis position.
   * @param {number} y - Y-axis position.
   * @param {number} z - Z-axis position.
   */
  public setPosition(x: number, y: number, z: number): void {
    this.mesh.position.set(x, y, z);
  }

  /**
   * Updates the background color and updates the shader uniform.
   * @param {Color} color - The new background color as a three.js Color object.
   */
  public setColor(color: Color): void {
    this.backgroundColor = color;
    this.uniforms.backgroundColor.value = new Vector3(color.r, color.g, color.b);
    this.material.needsUpdate = true;
  }

  /**
   * Returns the material used by the background mesh.
   * @returns {MeshBasicMaterial} The material for the background mesh.
   */
  public getMaterial(): MeshBasicMaterial {
    return this.material;
  }

  /**
   * Updates the visibility of the background mesh.
   * @param {boolean} value - Whether the background is visible.
   */
  public setVisibility(value: boolean): void {
    this.visible = value;
  }

  /**
   * Applies a texture map to the background material.
   * @param {Texture} map - The new texture to be used.
   */
  public setMap(map: Texture): void {
    this.texture = map;
    this.material.map = this.texture;
    this.material.needsUpdate = true;
  }

  /**
   * Toggles whether the background should be treated as an environment map.
   * @param {boolean} b - True to treat it as an environment map, false otherwise.
   */
  public setIsEnvironment(b: boolean): void {
    this.uniforms.isEnvironment.value = b;
  }

  /**
   * Updates the focus length uniform, affecting depth of field effects.
   * @param {number} value - The new focus length value.
   */
  public setFocus(value: number): void {
    this.uniforms.focusLength.value = value;
  }
}
