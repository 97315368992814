// const SIGGRAPH_GOLIVE_TESTER_IDS = [
//   '113424',
//   '108710',
//   '106780',
//   '118229',
//   // '244',
//   '282',
//   '1569',
// ];
// const EDITOR_LIVE_ROOM_TESTER_IDS = ['123521']; // JZ
// const BETA_TESTER_IDS = [...SIGGRAPH_GOLIVE_TESTER_IDS, ...EDITOR_LIVE_ROOM_TESTER_IDS];

const RADICAL_EMAILS = [
  'aleks@radicalmotion.com',
  'alice@radicalmotion.com',
  'blaz@radicalmotion.com',
  'burak@radicalmotion.com',
  'corentin@radicalmotion.com',
  'dario@radicalmotion.com',
  'erik@radicalmotion.com',
  'gavan@radicalmotion.com',
  'giovanni@radicalmotion.com',
  'guest@radicalmotion.com',
  'jacopo@radicalmotion.com',
  'konstantin@radicalmotion.com',
  'matteo@radicalmotion.com',
  'peter@radicalmotion.com',
  'pooya@radicalmotion.com',
  'sarah@radicalmotion.com',
  'srdjan@radicalmotion.com',
  'support@getrad.co',
  'svuetka@gmail.com',
  'vlad@radicalmotion.com',
  'vlad.marchenko@radicalmotion.com',
  'assets@radicalmotion.com',
];
const BETA_TESTER_EMAILS = [...RADICAL_EMAILS];

const BETA_FEATURES = [
  'EDITOR_LIVE_ROOM',
  'SHOW_DEVELOPMENT_OPTIONS',
  'SHOW_LIVE_SCENE_MODAL',
  'SHOW_UPLOAD_HDR_FEATURE',
  'ENABLE_THEATRE_TIMELINE',
  'USE_SHARE_PERMISSIONS_MODAL',
];

interface IUserInfo {
  id?: string;
  email?: string;
}

export function isBetaTester(user?: IUserInfo | null): boolean {
  // if (BETA_TESTER_IDS.includes(String(user?.id)) || BETA_TESTER_EMAILS.includes(String(user?.email))) {
  if (BETA_TESTER_EMAILS.includes(String(user?.email))) {
    return true;
  }

  return false;
}

export const useFeatureFlag = (flag: string, user?: IUserInfo | null): boolean => {
  if (!!user && isBetaTester(user) && BETA_FEATURES.includes(String(flag).toUpperCase())) {
    return true;
  }

  // @ts-ignore
  return window.__ENV__[flag] === true;
};

export const useIsClonePublishAllowed = (user: IUserInfo): boolean => {
  const allowed =
    //@ts-ignore
    window.__ENV__.ALLOW_CLONE_AND_PUBLISH
      ? //@ts-ignore
        window.__ENV__.ALLOW_CLONE_AND_PUBLISH.includes(user.email)
      : false;

  return allowed;
};
