import { GLSL3 } from 'three';

export function updateShader(shader: any, uniforms: any) {
  shader.glslVersion = GLSL3; // Specify the GLSL version to use

  shader.uniforms = {
    ...shader.uniforms,
    ...uniforms
  };

  // Insert custom GLSL code and uniforms into the fragment shader
  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <common>`,
    `#include <common>
    uniform vec3 backgroundColor;
    uniform bool isEnvironment;
    uniform float focusLength;
    layout(location = 0) out vec4 base_FragColor;
    layout(location = 1) out vec4 bloom_FragColor;
    layout(location = 2) out vec4 depth_FragColor;
    `
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <opaque_fragment>`,

    `#ifdef OPAQUE
    diffuseColor.a = 1.0;
    #endif
    
    #ifdef USE_TRANSMISSION
    diffuseColor.a *= material.transmissionAlpha;
    #endif
    
    bloom_FragColor = vec4( 0.0, 0.0, 0.0, diffuseColor.a );
    base_FragColor = vec4( mix(backgroundColor, outgoingLight, float(isEnvironment)), diffuseColor.a );

    float depthColor = smoothstep(30.0, 27.5, focusLength);
    depth_FragColor = vec4( depthColor, 0.0, 0.0, diffuseColor.a );
    `
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <tonemapping_fragment>`,
    `#if defined( TONE_MAPPING )
      bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
      base_FragColor.rgb = toneMapping( base_FragColor.rgb );
    #endif`
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <colorspace_fragment>`,
    `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
    base_FragColor = linearToOutputTexel( base_FragColor );
    `
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <fog_fragment>`,
    `#ifdef USE_FOG
        #ifdef FOG_EXP2
          float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
        #else
          float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
        #endif
        bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
        base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
    #endif`
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <premultiplied_alpha_fragment>`,
    `#ifdef PREMULTIPLIED_ALPHA
      // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
      bloom_FragColor.rgb *= bloom_FragColor.a;
      base_FragColor.rgb *= base_FragColor.a;
    #endif`
  );

  shader.fragmentShader = shader.fragmentShader.replace(
    `#include <dithering_fragment>`,
    `#ifdef DITHERING
      bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
      base_FragColor.rgb = dithering( base_FragColor.rgb );
    #endif`
  );
}
