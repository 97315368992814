import { useEnvParams } from '@radical/room-ui-env-params';
import { createContext, useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';

export type UserParams = {
  address: {
    country: string;
    state: string;
    city: string;
    address_1: string;
    address_2: string;
    postcode: string;
  };
  token: string;
  first_name: string;
  global_permissions: string[];
  last_name: string;
  phone: string;
  user_display_name: string;
  user_email: string;
  user_id: string;
  avatar: string;
  isFreeUser: boolean;
};

export type UserLimits = {
  assetUploadMaxSize: number;
  characterUploadMaxSize: number;
  canvasAvailableCount: number;
  maxVideos: number;
};

type UserInfoState = {
  user: UserParams | undefined;
  limits: UserLimits;
  devMode: boolean;
};

type UserInfoActions = {
  setUserInfo: (u: UserParams) => void;
  setDevMode: (b: boolean) => void;
};

const initialUserInfoState: UserInfoState = {
  user: undefined,
  limits: {
    assetUploadMaxSize: 0,
    characterUploadMaxSize: 0,
    canvasAvailableCount: 0,
    maxVideos: 0,
  },
  devMode: false,
};
const initialUserInfoActions: UserInfoActions = {
  setUserInfo: () => null,
  setDevMode: () => null,
};

export const UserInfoContext = createContext({ state: initialUserInfoState, actions: initialUserInfoActions });

export const UserInfoProvider = ({ isFreeUser, children }: { isFreeUser?: boolean; children: React.ReactElement }) => {
  const { env } = useEnvParams();
  const [state, setState] = useState(initialUserInfoState);
  const stateRef = useRef(state);

  const setUserInfo = (user: UserParams) => {
    stateRef.current.user = user;
    setState(stateRef.current);
  };

  const setDevMode = (b: boolean) => {
    stateRef.current.devMode = b;
    setState(stateRef.current);
  };

  useEffect(() => {
    if (env && env.USER_INFO_API !== '') {
      const token: string =
        (axios.defaults.headers.common['Authorization'] as string) ?? `Bearer ${env.USER_TOKEN || env.CANVAS_TOKEN}`;

      axios
        .get(env.USER_INFO_API, {
          headers: { Authorization: token },
        })
        .then((response) => {
          const data = response.data;
          stateRef.current = {
            ...stateRef.current,
            user:
              typeof data.data === 'object'
                ? { ...data.data, isFreeUser, user_id: String(data.data.user_id), token: token.replace('Bearer ', '') }
                : undefined,
          };
          setState(stateRef.current);
        });

      axios
        .get(env.USER_INFO_API + '/limits', {
          headers: { Authorization: token },
        })
        .then((response) => {
          const data = response.data;
          stateRef.current = {
            ...stateRef.current,
            limits: {
              assetUploadMaxSize: parseInt(data.data.asset_max_size),
              characterUploadMaxSize: parseInt(data.data.character_max_size),
              canvasAvailableCount: parseInt(data.data.canvas_available_count),
              maxVideos: parseInt(data.data.max_videos),
            },
          };
          setState(stateRef.current);
        });
    }
  }, [env]);

  const actions = { setUserInfo, setDevMode };
  return <UserInfoContext.Provider value={{ state, actions }}>{children}</UserInfoContext.Provider>;
};

export function useUserInfo() {
  const {
    state: { user, limits, devMode },
    actions: { setUserInfo, setDevMode },
  } = useContext(UserInfoContext);
  return { user, limits, devMode, setUserInfo, setDevMode };
}
