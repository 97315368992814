import { GLSL3 } from "three";

export function updateShader(shader: any) {
    // Specify the GLSL version
    shader.glslVersion = GLSL3;

    if (shader.fragmentShader.includes(`#include <packing>`) === false) {
      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <common>`,
        `#include <packing>
        #include <common>`
      );
    }

    shader.vertexShader = shader.vertexShader.replace(
      `#include <common>`,
      `varying float vViewZDepth;
      #include <common>`
    );

    shader.vertexShader = shader.vertexShader.replace(
      `#include <project_vertex>`,
      `#include <project_vertex>
      vViewZDepth = - mvPosition.z;
      `
    );

    // Modify the fragment shader to include a custom uniform for controlling bloom intensity
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      varying float vViewZDepth;
      layout(location = 0) out vec4 base_FragColor;
      layout(location = 1) out vec4 bloom_FragColor;
      layout(location = 2) out vec4 depth_FragColor;
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
      diffuseColor.a = 1.0;
      #endif
      
      #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha;
      #endif
      
      base_FragColor = vec4( outgoingLight, diffuseColor.a );
      bloom_FragColor = vec4( 0.0, 0.0, 0.0, diffuseColor.a );

      float cameraNear = 1.0; //hard coded for now
      float cameraFar = 30.0;
      float color = 1.0 - smoothstep( cameraNear, cameraFar, vViewZDepth );
      depth_FragColor = vec4( vec3( color ), 1.0 );

      `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
        bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
        base_FragColor.rgb = toneMapping( base_FragColor.rgb );
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
                  base_FragColor = linearToOutputTexel( base_FragColor );
                  `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
                    #ifdef FOG_EXP2
                      float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
                    #else
                      float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
                    #endif
                    bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
                    base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
                #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
                    // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
                    bloom_FragColor.rgb *= bloom_FragColor.a;
                    base_FragColor.rgb *= base_FragColor.a;
                  #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
                    bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
                    base_FragColor.rgb = dithering( base_FragColor.rgb );
                  #endif`
    );
  };