
const mixPassVertexShader = `
out vec2 vUv;
void main() {
	vUv = uv;
	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}`;

const mixPassFragmentShader = `
uniform sampler2D baseTexture;
uniform sampler2D bloomTexture;
in vec2 vUv;
out vec4 out_fragColor;
void main() {
	// out_fragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
	vec4 bloom = texture2D( bloomTexture, vUv );
	vec4 base = texture2D( baseTexture, vUv );
	out_fragColor = base;//mix(bloom, base, 0.5);// ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );//bloom;//
}
`;

export { mixPassVertexShader, mixPassFragmentShader }