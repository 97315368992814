
const cropVertexShader = `
out vec2 vUv;
void main() {
	vUv = uv;
	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}`;

const cropFragmentShader = `
// Uniforms
uniform float uCanvasAspectRatio;  	// Canvas aspect ratio
uniform float uTargetAspectRatio;  	// Target aspect ratio
uniform float uBlackBarOpacity;
uniform sampler2D tDiffuse;			// The scene texture

// Varyings
varying vec2 vUv;                 // UV coordinates

void main() {
    // Calculate the scaling factors to fit the target aspect ratio
    float scaleWidth = 1.0;
    float scaleHeight = 1.0;

    if (uCanvasAspectRatio > uTargetAspectRatio) {
        // Canvas is wider than the target aspect ratio, so we add black bars on the sides
        scaleWidth = uTargetAspectRatio / uCanvasAspectRatio;
    } else {
        // Canvas is taller than the target aspect ratio, so we add black bars on the top and bottom
        scaleHeight = uCanvasAspectRatio / uTargetAspectRatio;
    }

    // Compute the UV bounds of the cropped area
    vec2 cropMin = vec2((1.0 - scaleWidth) / 2.0, (1.0 - scaleHeight) / 2.0);
    vec2 cropMax = vec2((1.0 + scaleWidth) / 2.0, (1.0 + scaleHeight) / 2.0);

    // Determine if the current fragment lies within the cropped area
    float insideCrop = step(cropMin.x, vUv.x) * step(vUv.x, cropMax.x) *
                       step(cropMin.y, vUv.y) * step(vUv.y, cropMax.y);

    // Sample the scene texture
    vec3 sceneColor = texture2D(tDiffuse, vUv).rgb;

    // Color of the black bar, black half transparent
    vec4 blackBar = mix(vec4(0.0, 0.0, 0.0, 1.0), vec4(sceneColor, 1.0), uBlackBarOpacity);

    // Mix black for areas outside the crop and content for the valid region
    gl_FragColor = mix(blackBar, vec4(sceneColor, 1.0), insideCrop);
}

`;

export { cropVertexShader, cropFragmentShader };
