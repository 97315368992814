import { Helmet } from 'react-helmet-async';
import Smiley from './images/404Face.svg';
export default function NotFound() {
  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div className="w-full min-h-[400px] flex flex-col items-center justify-center">
        <div className="max-w-[450px] bg-white py-[25px] px-[35px] rounded-[6px] shadow-md">
          <div className="flex gap-4 items-center pb-6">
            <img src={Smiley} width={30} height={30} />
            <p className="font-bold">404 - Not Found</p>
          </div>
          <p>Sorry, but you are looking for something that isn't here.</p>
        </div>
      </div>
    </>
  );
}
